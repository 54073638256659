*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.container{
  max-width: 1200px;
  padding: 0 30px;
}

.App{
  overflow: hidden;
}

