.advertising{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background-color: rgba(56, 56, 99, 0.724);
    position: relative;
    border-radius: 30px;
    padding: 30px;
    margin-top: 150px;
    height: 300px;
    margin-bottom: 100px;
}

.advertising-left{
    width: 300px;
    padding-left: 50px;
}

.adven-left-title{
    color: white;
    font-size: 70px;
    z-index: 2;
}

.adven-desc{
    font-size: 16px;
    color:rgba(255, 255, 255, 0.727);
    z-index: 2;
}

.adven-img{
    position: absolute;
    top: -160px;
    left: 23%;
    width: 400px;
    z-index: 1;
}

.advertising-right{
    width: 35%;
}

.adven-right-title{
    font-size: 24px;
    color: white;
    
}

@media only screen and (max-width: 995px){
    .adven-img{
        width: 200px;
        top: 0;
        left: 36%;
    }
}

@media only screen and (max-width: 770px){
    .advertising{
        justify-content: center;
        flex-direction: column;
        height: fit-content;
        margin-bottom: 100px;
        padding: 20px;
        padding-top: 60px;
    }
    
    .advertising-left{
        width: 100%;
        padding-left: 0;
        text-align: center;
    }
    
    .adven-left-title{
        font-size: 40px;
    }
    
    .adven-desc{
        font-size: 16px;
    }
    
    .adven-img{
        top: -140px;
        left: auto;
        width: 150px;
    }
    
    .advertising-right{
        width:100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
    }
    
    
}