.home{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 90vh;
    background-color: rgb(52, 44, 44);
    border-radius: 30px;
    position: relative;
    margin-bottom: 50px;
}

.home-left{
    width: 55%;

}
.home-img{
    position: absolute;
    top: 0;
    left: -170px;
    width: 1150px;
    height: 600px;
    /* z-index: -1;  */

}

.home-right{
    width: 45%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
}

.home-right-block{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
}

.home-city{
    color: white;
    font-size: 28px;
    padding: 10px
}

.home-title{
    color: white;
    font-size: 40px;
}

.home-produc-title{
    color: white;
    font-size: 55px;
}

.info{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin: 50px 0;
    margin-bottom: 100px;
}

.info-card{
    width: 250px;
    display: flex;
    margin: 0 10px;
}

.curier{
    padding-right: 15px;
    width: 70px;
    /* height: 50px; */
}

.info-title{
    font-size: 16px;
}

.info-desc{
    color: rgb(170, 188, 189);
    font-size: 14px;
}

@media only screen and (max-width: 770px){
    .home{
        justify-content: center;
        flex-direction: column;
        height: 90vh;
    }
    
    .home-left{
        width: 100%;
    
    }
    .home-img{
        top: -50px;
        left: 0;
        width: 500px;
    }
    
    .home-right{
        width: 100%;
    }
    
    .home-right-block{
        margin-right: 0;
    }
    
    .home-city{
        font-size: 24px;
    }
    
    .home-title{
        font-size: 30px;
    }
    
    .home-produc-title{
        font-size: 45px;
    }

    .info-card{
        width: 100%;
    }
}

@media only screen and (max-width: 420px){
    .home{
        height: 200px;
    }

    .home-img{
        display: none;
    }
}