/* .products{
} */


.products-title{
    font-size: 50px;
    text-align: center;
    padding-top: 40px;
}

.products-desc{
    font-size: 14px;
    color: rgba(0, 0, 0, 0.339);
    text-align: center;
    padding-bottom: 40px;
}

.products-block{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.products-card{
    width: 200px;
    margin: 20px 10px;
    text-decoration: none;
    color: rgba(0, 0, 0, 0.557);
    padding: 5px;
}

.products-card:hover{
    color: red;
    padding: 0;
    transition: 0.2s ease-in-out;
}

.products-img{
    width: 100%;
    border-radius: 10px;
}

.products-block-title{
    font-size: 15px;
    padding: 10px 0;
}

@media only screen and (max-width: 465px){
    .products-card{
        width: 100%;
    }

    .products-block-title{
        font-size: 18px;
    }
}