.modal{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.488);
    backdrop-filter: blur(4px);
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    transition: 0.5s ease-in-out;
}

.modal-open{
    opacity: 1;
    visibility: visible;
    transition: 0.5s ease-in-out;
}

.modal-content{
    width: 300px;
    height: 200px;
    background-color: white;
    padding: 40px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.close-img{
    width: 40px;
    height: 40px;
    position: absolute;
    top: 10px;
    right: 10px;
}

.modal-text{
    color: rgb(10, 184, 10);
    font-size: 20px;
}