.products-table{
    font-size: 14px!important;
}

.products-info-block{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 40px;
}

.products-info-img{
    width: 50%;
}

.products-info-right{
    width: 40%;
}

.description-block{
    width: 80%;
    margin: auto;
}

@media only screen and (max-width: 780px){
    
    .products-info-block{
        display: block;
        justify-content: center;
    }
    
    .products-info-img{
        width: 100%;
    }
    
    .products-info-right{
        width: 100%;
    }

    .description-block{
        width: 100%;
    }
}